.login-header h2 {
  font-size: 30px;
}

input[type="textarea"] {
  height: 60px !important;
}

.error-input p {
  color: red;
  font-size: 12px;
  margin: 0 0 12px;
}

.eye-icon-span {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: grey;
}
