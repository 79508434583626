
.clinic-booking .cart_btn{
    border: 2px solid #20c0f3;
    background-color: #20c0f3;
    color: #20c0f3;
    display: block;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: center;
    text-transform: capitalize;
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
}

